const defaultLabel = {
  color: null,
  icon: 'home'
};
export const findLabel = label => labels.find(_label => _label.label === label) || defaultLabel;
export const labels = [
  {
    label: 'Statutul unității administrativ-teritoriale',
    color: 'purple',
    icon: 'map'
  },
  {
    label: 'Regulamentele privind procedurile administrative',
    color: 'orange',
    icon: 'description'
  },
  {
    label: 'Hotărârile autorității deliberative',
    color: 'brown',
    icon: 'record_voice_over'
  },
  {
    label: 'Dispozițiile autorității executive',
    color: 'blue-grey',
    icon: 'gavel'
  },
  {
    label: 'Documente și informații financiare',
    color: 'teal',
    icon: 'euro_symbol'
  },
  {
    label: 'Alte documente',
    color: 'pink',
    icon: 'file_copy'
  }
];
export const actions = [
  {
    label: 'Utilizator nou',
    icon: 'people',
    route: { name: 'users.create' },
    roles: ['developer', 'administrator']
  },
  {
    label: 'Institutie noua',
    icon: 'work',
    route: { name: 'institutions.create' },
    roles: ['developer']
  },
  {
    label: 'Fisier nou',
    icon: 'picture_as_pdf',
    route: { name: 'files.create' },
    roles: ['developer', 'administrator']
  }
];
export const links = [
  {
    label: 'Monitorul Oficial',
    url: 'https://monitoroficiallocal.ro/'
  },
  {
    label: 'Aplicatii GR8',
    url: 'https://ultra-vision.ro/produse/'
  }
];
